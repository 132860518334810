import { Component, OnDestroy, OnInit } from '@angular/core';
import { User, UsersService } from '../../services/users.service';
import { AccountService } from '../../services/account-service';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { first, takeWhile } from 'rxjs/operators';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
})
export class ChangePasswordComponent implements OnInit, OnDestroy {

  private isAlive = true;

  private user: User;

  public form;

  constructor(
    fb: FormBuilder,
    private account: AccountService,
    private users: UsersService,
    private snackBar: MatSnackBar,
    private router: Router
  ) {
    this.form = fb.group({
      newPw: new FormControl('', [Validators.required]),
      pwRepeat: new FormControl('', [Validators.required]),
    }, {validator: this.validatePwRepeat});
  }

  ngOnInit() {
    this.account.user$
      .pipe(takeWhile(() => this.isAlive))
      .subscribe(user => this.user = user);
  }

  ngOnDestroy() {
    this.isAlive = false;
  }

  submit() {
    if (!this.form.valid || !this.user) {
      return;
    }
    const values = this.form.value;

    this.users
      .update(this.user.id, {
        forcePasswordChange: false,
        password: values.newPw,
      })
      .pipe(first())
      .subscribe(() => {
        this.snackBar.open(
          'Passwort geändert!',
          '',
          <MatSnackBarConfig>{
            duration: 5000
          });
        this.router.navigate(['dashboard']);
      }, (e) => {
        this.snackBar.open('Passwort ändern fehlgeschlagen', '', <MatSnackBarConfig>{
          duration: 5000
        });
      });
  }

  validatePwRepeat(group) {
    const {newPw, pwRepeat} = group.controls;
    if (newPw.value === pwRepeat.value) {
      pwRepeat.setErrors(null);
      return null;
    }
    pwRepeat.setErrors({
      mismatch: true
    });

    return {
      mismatch: true
    };
  }
}
