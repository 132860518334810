<header>
  <mat-toolbar color="primary" *ngIf="account?.user$ | async as user; else titleOnly">
    <a mat-mini-fab color="accent" (click)="toggleMenu.emit()">
      <mat-icon>menu</mat-icon>
    </a>
    <span class="text-overflow" fxHide.xs>Buchungssystem</span>
    <span class="spacer"></span>
    <a routerLink="change-password" mat-button class="account">
      <mat-icon>account_circle</mat-icon>
      <span>{{ user?.username }}</span>
    </a>
    <a mat-mini-fab color="accent" (click)="logout.emit()">
      <mat-icon>exit_to_app</mat-icon>
    </a>
  </mat-toolbar>
  <ng-template #titleOnly>
    <mat-toolbar color="primary">
      <span>Buchungssystem</span>
    </mat-toolbar>
  </ng-template>
</header>
