import { Injectable } from '@angular/core';
import { FeathersRestService } from './feathers-rest.service';
import { AccountService } from './account-service';
import { HttpClient } from '@angular/common/http';

export interface MurderEvent {
  id?: number;
  eventPageId?: number;
  title: string;
  locationId: number;
  paymentId: number;
  entriesAt: string;
  beginsAt: string;
  ticketsSold?: number;
  ticketPriceCent: number;
  ticketsAvailable: number;
  soldOut: 0 | 1;
  inactive?: 0 | 1;
}

export interface MurderEventWithAssociations extends MurderEvent {
  'location.id'?: number;
  'location.name'?: string;
}

@Injectable()
export class EventsService extends FeathersRestService<MurderEvent> {

  protected apiUrl = '/api/event';

  constructor(
    protected account: AccountService,
    protected http: HttpClient,
  ) {
    super(account, http);
  }

}
