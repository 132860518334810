import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, throwError } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
var AccountService = /** @class */ (function () {
    function AccountService(http) {
        this.http = http;
        this.userId = '';
        this.isAdmin = false;
        try {
            this.restoreFromLocalStorage();
        }
        catch (e) {
            console.error(e);
        }
        this._token$ = new BehaviorSubject(this.token);
        this._user$ = new BehaviorSubject(this.user);
        this.parseToken(this.token);
    }
    Object.defineProperty(AccountService.prototype, "isAuthenticated", {
        get: function () {
            return !!this.token && this.expiresAt > Date.now();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AccountService.prototype, "token$", {
        get: function () {
            return this._token$;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AccountService.prototype, "user$", {
        get: function () {
            return this._user$;
        },
        enumerable: true,
        configurable: true
    });
    AccountService.prototype.auth = function (_a) {
        var _this = this;
        var username = _a.username, password = _a.password;
        var headers = new HttpHeaders({
            Accept: 'application/json',
            'Content-Type': 'application/json'
        });
        return this.http
            .post('/api/authentication', {
            username: username,
            password: password,
            strategy: 'local'
        }, { headers: headers })
            .pipe(switchMap(function (res) { return _this.parseAuthResponse(res); }), catchError(function (e) {
            console.error(e);
            return throwError(e);
        }));
    };
    AccountService.prototype.refreshAuth = function () {
        var _this = this;
        if (!this.token) {
            return this._user$;
        }
        var headers = new HttpHeaders({
            Accept: 'application/json',
            Authorization: "Bearer " + this.token,
            'Content-Type': 'application/json'
        });
        return this.http
            .post('/api/authentication', null, { headers: headers })
            .pipe(switchMap(function (res) { return _this.parseAuthResponse(res); }), catchError(function (e) {
            console.error(e);
            return throwError(e);
        }));
    };
    AccountService.prototype.logout = function () {
        localStorage.clear();
        location.reload();
    };
    AccountService.prototype.restoreFromLocalStorage = function () {
        var token = localStorage.getItem('token');
        this.token = token != null ? token : '';
        this.user = JSON.parse(localStorage.getItem('user'));
        if (this.user) {
            this.isAdmin = this.user.role === 'admin';
        }
    };
    AccountService.prototype.parseAuthResponse = function (res) {
        if (res === void 0) { res = {}; }
        var accessToken = res.accessToken, user = res.user;
        if (!accessToken) {
            return throwError(new Error('auth failed'));
        }
        if (!user) {
            return throwError(new Error('no user info present'));
        }
        if (!this.parseToken(accessToken)) {
            return throwError(new Error('parsing token failed'));
        }
        localStorage.setItem('token', accessToken);
        localStorage.setItem('user', JSON.stringify(user));
        this.token = accessToken;
        this.isAdmin = user.role === 'admin';
        this._token$.next(accessToken);
        this._user$.next(user);
        return this._user$;
    };
    AccountService.prototype.parseToken = function (token) {
        if (!token) {
            return false;
        }
        try {
            var _a = token.split('.'), header = _a[0], _b = _a[1], payload = _b === void 0 ? '' : _b;
            var auth = JSON.parse(atob(payload));
            var _c = auth.exp, exp = _c === void 0 ? 0 : _c, userId = auth.userId;
            if (!userId || !exp) {
                return false;
            }
            this.userId = userId;
            this.expiresAt = exp * 1000;
        }
        catch (e) {
            console.error(e);
            return false;
        }
        return true;
    };
    return AccountService;
}());
export { AccountService };
