import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FlexLayoutModule } from '@angular/flex-layout';
import { HttpClientModule } from '@angular/common/http';
import { CapitalizePipe } from './pipes/capitalize';
import { AngularMaterialModule } from './angular-material.module';
import { AuthGuard } from './services/auth-guard.service';
import { AccountService } from './services/account-service';
import { UsersService } from './services/users.service';
import { LoginComponent } from './components/login/login.component';
import { ChangePasswordComponent } from './components/change-password/change-password.component';
import { LocationsService } from './services/locations.service';
import { LocationsDataSourceService } from './services/locations-data-source.service';
import { PaymentsService } from './services/payments.service';
import { PaymentsDataSourceService } from './services/payments-data-source.service';
import { EventsService } from './services/events.service';
import { EventsDataSourceService } from './services/events-data-source.service';
import { PricePipe } from './pipes/price.pipe';
import { FormSubmitComponent } from './components/form-submit/form-submit.component';
import { BookingsDataSourceService } from './services/bookings-data-source.service';
import { BookingsService } from './services/bookings.service';
import { Nl2brPipe } from './pipes/nl2br.pipe';
import { MatSnackBar } from '@angular/material';
import { UsersDataSourceService } from './services/users-data-source.service';

const exports = [
  FlexLayoutModule,
  AngularMaterialModule,
  FormsModule,
  ReactiveFormsModule,
  HttpClientModule,
  CapitalizePipe,
  PricePipe,
  Nl2brPipe,
  LoginComponent,
  ChangePasswordComponent,
  FormSubmitComponent,
];

export function bookingsServiceFactory(bookingsService: BookingsService, snackBar: MatSnackBar) {
  return new BookingsDataSourceService(bookingsService, snackBar);
}

export function locationsServiceFactory(locationsService: LocationsService, snackBar: MatSnackBar) {
  return new LocationsDataSourceService(locationsService, snackBar);
}

export function paymentsServiceFactory(paymentsService: PaymentsService, snackBar: MatSnackBar) {
  return new PaymentsDataSourceService(paymentsService, snackBar);
}

export function eventsServiceFactory(eventsService: EventsService, snackBar: MatSnackBar) {
  return new EventsDataSourceService(eventsService, snackBar);
}

export function usersServiceFactory(usersService: UsersService, snackBar: MatSnackBar) {
  return new UsersDataSourceService(usersService, snackBar);
}

const providers = [
  AuthGuard,
  AccountService,
  EventsService,
  LocationsService,
  PaymentsService,
  UsersService,
  BookingsService,
  {
    provide: BookingsDataSourceService,
    useFactory: bookingsServiceFactory,
    deps: [BookingsService, MatSnackBar]
  },
  {
    provide: LocationsDataSourceService,
    useFactory: locationsServiceFactory,
    deps: [LocationsService, MatSnackBar]
  },
  {
    provide: PaymentsDataSourceService,
    useFactory: paymentsServiceFactory,
    deps: [PaymentsService, MatSnackBar]
  },
  {
    provide: EventsDataSourceService,
    useFactory: eventsServiceFactory,
    deps: [EventsService, MatSnackBar]
  },
  {
    provide: UsersDataSourceService,
    useFactory: usersServiceFactory,
    deps: [UsersService, MatSnackBar]
  },
];

@NgModule({
  declarations: [
    CapitalizePipe,
    PricePipe,
    Nl2brPipe,
    LoginComponent,
    ChangePasswordComponent,
    FormSubmitComponent,
  ],
  imports: [
    CommonModule,
    FlexLayoutModule,
    AngularMaterialModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
  ],
  exports,
  providers,
})
export class SharedModule {
  static forRoot() {
    return {
      ngModule: SharedModule,
      exports,
      providers,
    };
  }
}
