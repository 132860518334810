var ɵ0 = {
    float: 'always'
}, ɵ1 = {
    duration: 5e3
};
var AngularMaterialModule = /** @class */ (function () {
    function AngularMaterialModule() {
    }
    return AngularMaterialModule;
}());
export { AngularMaterialModule };
export { ɵ0, ɵ1 };
