import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-form-submit',
  styleUrls: ['./form-submit.component.scss'],
  templateUrl: './form-submit.component.html',
})
export class FormSubmitComponent {

  @Input()
  public disabled = false;
}
