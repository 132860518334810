import * as tslib_1 from "tslib";
import { AbstractDataSource } from './abstract-data-source';
import { LocationsService } from './locations.service';
import { MatSnackBar } from '@angular/material';
var LocationsDataSourceService = /** @class */ (function (_super) {
    tslib_1.__extends(LocationsDataSourceService, _super);
    function LocationsDataSourceService(locationsService, snackBar) {
        var _this = _super.call(this, locationsService, snackBar) || this;
        _this.locationsService = locationsService;
        _this.snackBar = snackBar;
        _this.apiQuery = {
            $client: {
                include: 1,
            }
        };
        return _this;
    }
    return LocationsDataSourceService;
}(AbstractDataSource));
export { LocationsDataSourceService };
