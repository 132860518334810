/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../node_modules/@angular/material/toolbar/typings/index.ngfactory";
import * as i2 from "@angular/material/toolbar";
import * as i3 from "@angular/cdk/platform";
import * as i4 from "@angular/common";
import * as i5 from "./not-found.component";
var styles_NotFoundComponent = [];
var RenderType_NotFoundComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_NotFoundComponent, data: {} });
export { RenderType_NotFoundComponent as RenderType_NotFoundComponent };
export function View_NotFoundComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "mat-toolbar", [["class", "mat-toolbar"]], [[2, "mat-toolbar-multiple-rows", null], [2, "mat-toolbar-single-row", null]], null, null, i1.View_MatToolbar_0, i1.RenderType_MatToolbar)), i0.ɵdid(1, 4243456, null, 1, i2.MatToolbar, [i0.ElementRef, i3.Platform, i4.DOCUMENT], null, null), i0.ɵqud(603979776, 1, { _toolbarRows: 1 }), (_l()(), i0.ɵted(-1, 0, [" 404\n"])), (_l()(), i0.ɵeld(4, 0, null, null, 2, "aside", [["class", "container"]], null, null, null, null, null)), (_l()(), i0.ɵeld(5, 0, null, null, 1, "h3", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, [" page not-found! "]))], null, function (_ck, _v) { var currVal_0 = (i0.ɵnov(_v, 1)._toolbarRows.length > 0); var currVal_1 = (i0.ɵnov(_v, 1)._toolbarRows.length === 0); _ck(_v, 0, 0, currVal_0, currVal_1); }); }
export function View_NotFoundComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-not-found", [], null, null, null, View_NotFoundComponent_0, RenderType_NotFoundComponent)), i0.ɵdid(1, 49152, null, 0, i5.NotFoundComponent, [], null, null)], null, null); }
var NotFoundComponentNgFactory = i0.ɵccf("app-not-found", i5.NotFoundComponent, View_NotFoundComponent_Host_0, {}, {}, []);
export { NotFoundComponentNgFactory as NotFoundComponentNgFactory };
