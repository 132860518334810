import { Injectable } from '@angular/core';
import { FeathersRestService } from './feathers-rest.service';
import { AccountService } from './account-service';
import { HttpClient } from '@angular/common/http';

export type BookingStatus = 'open' | 'paid' | 'confirmed' | 'reminded' | 'canceled';

export interface Booking {
  id?: number;
  eventId?: number;
  paymentId?: number;
  locationId: number;
  firstname: string;
  lastname: string;
  street: string;
  postal: string;
  city: string;
  phone: string;
  email: string;
  voucherPhone?: string;
  voucherEmail?: string;
  presentee: string;
  extras: string;
  priceCent: number;
  ticketsTotal: number;
  ticketsVegetarians: number;
  ticketsSold?: number;
  isVoucher: 0 | 1;
  voucherCode: string;
  status: BookingStatus;
  confirmedAt: string;
  remindedAt: string;
  canceledAt: string;
  createdAt: string;
  updatedAt: string;
}

@Injectable()
export class BookingsService extends FeathersRestService<Booking> {
  protected apiUrl = '/api/booking';

  constructor(
    protected account: AccountService,
    protected http: HttpClient,
  ) {
    super(account, http);
  }
}
