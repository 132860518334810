import * as tslib_1 from "tslib";
import { AbstractDataSource } from './abstract-data-source';
import { MatSnackBar } from '@angular/material';
import { UsersService } from './users.service';
var UsersDataSourceService = /** @class */ (function (_super) {
    tslib_1.__extends(UsersDataSourceService, _super);
    function UsersDataSourceService(usersService, snackBar) {
        var _this = _super.call(this, usersService, snackBar) || this;
        _this.usersService = usersService;
        _this.snackBar = snackBar;
        return _this;
    }
    return UsersDataSourceService;
}(AbstractDataSource));
export { UsersDataSourceService };
