import * as tslib_1 from "tslib";
import { AccountService } from './account-service';
import { FeathersRestService } from './feathers-rest.service';
import { HttpClient } from '@angular/common/http';
var UsersService = /** @class */ (function (_super) {
    tslib_1.__extends(UsersService, _super);
    function UsersService(account, http) {
        var _this = _super.call(this, account, http) || this;
        _this.account = account;
        _this.http = http;
        _this.apiUrl = '/api/user';
        return _this;
    }
    return UsersService;
}(FeathersRestService));
export { UsersService };
