import { Component, HostListener, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatSidenav } from '@angular/material';
import { MediaChange, ObservableMedia } from '@angular/flex-layout';
import { debounceTime, filter, first, takeWhile } from 'rxjs/operators';
import { environment } from '../environments/environment';

import { AccountService } from './shared/services/account-service';

@Component({
  encapsulation: ViewEncapsulation.Emulated,
  selector: 'app-root',
  styleUrls: ['./app.component.scss'],
  templateUrl: './app.component.html',
})
export class AppComponent implements OnInit, OnDestroy {

  @ViewChild('sidebar')
  private sidebar: MatSidenav;

  public mode = 'side';

  public isSidebarOpen = true;

  public activeMq = '';

  private isAlive = true;

  constructor(
    private media: ObservableMedia,
    public account: AccountService
  ) {
  }

  public ngOnInit() {
    this.media
      .asObservable()
      .pipe(
        takeWhile(() => this.isAlive),
        filter((mq: MediaChange) => mq.matches),
        debounceTime(100),
      )
      .subscribe(({mqAlias}: MediaChange) => {
        this.activeMq = mqAlias;
        if (mqAlias === 'xs' || mqAlias === 'sm') {
          this.renderPhone();
          return;
        }
        this.renderDesktop();
      });
  }

  public ngOnDestroy() {
    this.isAlive = false;
  }

  openSidebar() {
    if (this.sidebar) {
      this.isSidebarOpen = true;
      this.sidebar.open();
    }
  }

  closeSidebar() {
    if (this.sidebar) {
      this.sidebar.close();
      this.isSidebarOpen = false;
    }
  }

  public closeWhenMobile() {
    if (this.mode === 'over') {
      this.closeSidebar();
    }
  }

  @HostListener('window:focus')
  public refreshAuth() {
    if (!environment.production || /(android|ios)/i.test(navigator.userAgent)) {
      return;
    }
    this.account.refreshAuth()
      .pipe(first())
      .subscribe(() => {
      }, () => this.logout());
  }

  private renderPhone() {
    this.mode = 'over';
    this.closeSidebar();
  }

  private renderDesktop() {
    this.mode = 'side';
    this.openSidebar();
  }

  public toggleSidebar() {
    this.sidebar.toggle();
  }

  public logout() {
    this.account.logout();
  }
}
