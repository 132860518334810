import { Injectable } from '@angular/core';
import { EventsService, MurderEventWithAssociations } from './events.service';
import { AbstractDataSource } from './abstract-data-source';
import { MatSnackBar } from '@angular/material';
import { ApiQuery } from './feathers-rest.service';

@Injectable()
export class EventsDataSourceService extends AbstractDataSource<MurderEventWithAssociations> {

  protected dateFilterProperty = 'beginsAt';

  public apiQuery: ApiQuery = {
    $client: {
      include: 1,
      sumTicketsSold: 1,
    }
  };

  constructor(protected eventsService: EventsService,
              protected snackBar: MatSnackBar
  ) {
    super(eventsService, snackBar);
  }
}

