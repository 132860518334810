import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, throwError } from 'rxjs';
import { User } from './users.service';
import { catchError, switchMap } from 'rxjs/operators';

interface TokenPayload {
  // aud: string;
  exp: number;
  // iat: number;
  // iss: string;
  // jti: string;
  // sub: string;
  userId: string;
}

interface AuthResponse {
  accessToken?: string;
  user?: User;
}

@Injectable()
export class AccountService {

  private _token$: BehaviorSubject<string>;
  private _user$: BehaviorSubject<any>;
  private token: string;
  private expiresAt: number;
  private user: User;

  public userId = '';

  public isAdmin = false;

  constructor(private http: HttpClient) {
    try {
      this.restoreFromLocalStorage();
    } catch (e) {
      console.error(e);
    }
    this._token$ = new BehaviorSubject(this.token);
    this._user$ = new BehaviorSubject(this.user);
    this.parseToken(this.token);
  }

  public get isAuthenticated(): boolean {
    return !!this.token && this.expiresAt > Date.now();
  }

  public get token$() {
    return this._token$;
  }

  public get user$() {
    return this._user$;
  }

  public auth({username, password}) {
    const headers = new HttpHeaders({
      Accept: 'application/json',
      'Content-Type': 'application/json'
    });
    return this.http
      .post(
        '/api/authentication',
        {
          username,
          password,
          strategy: 'local'
        },
        {headers}
      )
      .pipe(
        switchMap((res: AuthResponse) => this.parseAuthResponse(res)),
        catchError(e => {
          console.error(e);
          return throwError(e);
        })
      );
  }

  public refreshAuth() {
    if (!this.token) {
      return this._user$;
    }
    const headers = new HttpHeaders({
      Accept: 'application/json',
      Authorization: `Bearer ${this.token}`,
      'Content-Type': 'application/json'
    });
    return this.http
      .post('/api/authentication', null, {headers})
      .pipe(
        switchMap((res: AuthResponse) => this.parseAuthResponse(res)),
        catchError(e => {
          console.error(e);
          return throwError(e);
        })
      );
  }

  public logout() {
    localStorage.clear();
    location.reload();
  }

  private restoreFromLocalStorage() {
    const token = <string>localStorage.getItem('token');
    this.token = token != null ? token : '';
    this.user = JSON.parse(localStorage.getItem('user')) as User;
    if (this.user) {
      this.isAdmin = this.user.role === 'admin';
    }
  }

  private parseAuthResponse(res: AuthResponse = {}) {
    const {accessToken, user} = res;
    if (!accessToken) {
      return throwError(new Error('auth failed'));
    }
    if (!user) {
      return throwError(new Error('no user info present'));
    }
    if (!this.parseToken(accessToken)) {
      return throwError(new Error('parsing token failed'));
    }
    localStorage.setItem('token', accessToken);
    localStorage.setItem('user', JSON.stringify(user));
    this.token = accessToken;
    this.isAdmin = user.role === 'admin';
    this._token$.next(accessToken);
    this._user$.next(user);
    return this._user$;
  }

  private parseToken(token: string) {
    if (!token) {
      return false;
    }
    try {
      const [header, payload = ''] = token.split('.');
      const auth = <TokenPayload>JSON.parse(atob(payload));
      const {exp = 0, userId} = auth;
      if (!userId || !exp) {
        return false;
      }
      this.userId = userId;
      this.expiresAt = exp * 1000;
    } catch (e) {
      console.error(e);
      return false;
    }
    return true;
  }
}
