import { OnDestroy, OnInit } from '@angular/core';
import { MatSidenav } from '@angular/material';
import { ObservableMedia } from '@angular/flex-layout';
import { debounceTime, filter, first, takeWhile } from 'rxjs/operators';
import { environment } from '../environments/environment';
import { AccountService } from './shared/services/account-service';
var AppComponent = /** @class */ (function () {
    function AppComponent(media, account) {
        this.media = media;
        this.account = account;
        this.mode = 'side';
        this.isSidebarOpen = true;
        this.activeMq = '';
        this.isAlive = true;
    }
    AppComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.media
            .asObservable()
            .pipe(takeWhile(function () { return _this.isAlive; }), filter(function (mq) { return mq.matches; }), debounceTime(100))
            .subscribe(function (_a) {
            var mqAlias = _a.mqAlias;
            _this.activeMq = mqAlias;
            if (mqAlias === 'xs' || mqAlias === 'sm') {
                _this.renderPhone();
                return;
            }
            _this.renderDesktop();
        });
    };
    AppComponent.prototype.ngOnDestroy = function () {
        this.isAlive = false;
    };
    AppComponent.prototype.openSidebar = function () {
        if (this.sidebar) {
            this.isSidebarOpen = true;
            this.sidebar.open();
        }
    };
    AppComponent.prototype.closeSidebar = function () {
        if (this.sidebar) {
            this.sidebar.close();
            this.isSidebarOpen = false;
        }
    };
    AppComponent.prototype.closeWhenMobile = function () {
        if (this.mode === 'over') {
            this.closeSidebar();
        }
    };
    AppComponent.prototype.refreshAuth = function () {
        var _this = this;
        if (!environment.production || /(android|ios)/i.test(navigator.userAgent)) {
            return;
        }
        this.account.refreshAuth()
            .pipe(first())
            .subscribe(function () {
        }, function () { return _this.logout(); });
    };
    AppComponent.prototype.renderPhone = function () {
        this.mode = 'over';
        this.closeSidebar();
    };
    AppComponent.prototype.renderDesktop = function () {
        this.mode = 'side';
        this.openSidebar();
    };
    AppComponent.prototype.toggleSidebar = function () {
        this.sidebar.toggle();
    };
    AppComponent.prototype.logout = function () {
        this.account.logout();
    };
    return AppComponent;
}());
export { AppComponent };
