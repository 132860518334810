import { throwError } from 'rxjs';
import { HttpHeaders } from '@angular/common/http';
import { catchError, first, map, shareReplay } from 'rxjs/operators';
var FeathersRestService = /** @class */ (function () {
    function FeathersRestService(account, http) {
        var _this = this;
        this.account = account;
        this.http = http;
        this.account.token$.subscribe(function (token) { return _this.token = token; });
    }
    FeathersRestService.prototype.find = function (query) {
        var _this = this;
        if (query === void 0) { query = {}; }
        return this.http
            .get(this.apiUrl + "/" + this.parseQuery(query), {
            headers: this.headers,
        })
            .pipe(first(), shareReplay(), catchError(function (e) { return _this.handleError(e); }));
    };
    FeathersRestService.prototype.count = function () {
        var _this = this;
        return this.http
            .get(this.apiUrl + "/?$limit=0", {
            headers: this.headers,
        })
            .pipe(first(), map(function (res) {
            return res.total;
        }), shareReplay(), catchError(function (e) { return _this.handleError(e); }));
    };
    FeathersRestService.prototype.get = function (id, query) {
        var _this = this;
        if (query === void 0) { query = {}; }
        return this.http
            .get(this.apiUrl + "/" + id + this.parseQuery(query), {
            headers: this.headers,
        })
            .pipe(first(), shareReplay(), catchError(function (e) { return _this.handleError(e); }));
    };
    FeathersRestService.prototype.create = function (data) {
        var _this = this;
        return this.http
            .post("" + this.apiUrl, data, {
            headers: this.headers,
        })
            .pipe(first(), shareReplay(), catchError(function (e) { return _this.handleError(e); }));
    };
    FeathersRestService.prototype.update = function (idOrCollection, data, query) {
        var _this = this;
        if (data === void 0) { data = null; }
        if (query === void 0) { query = {}; }
        var targetsSingleEntity = !Array.isArray(idOrCollection);
        var url = targetsSingleEntity ? this.apiUrl + "/" + idOrCollection : "" + this.apiUrl;
        url += "/" + this.parseQuery(query);
        return this.http
            .patch(url, targetsSingleEntity ? data : idOrCollection, {
            headers: this.headers,
        })
            .pipe(first(), shareReplay(), catchError(function (e) { return _this.handleError(e); }));
    };
    FeathersRestService.prototype.replace = function (id, data) {
        var _this = this;
        return this.http
            .put(this.apiUrl + "/" + id, data, {
            headers: this.headers,
        })
            .pipe(first(), shareReplay(), catchError(function (e) { return _this.handleError(e); }));
    };
    FeathersRestService.prototype.delete = function (id) {
        var _this = this;
        return this.http
            .delete(this.apiUrl + "/" + id, {
            headers: this.headers,
        })
            .pipe(first(), shareReplay(), catchError(function (e) { return _this.handleError(e); }));
    };
    FeathersRestService.prototype.emptyFindResponse = function (limit, skip) {
        if (limit === void 0) { limit = 0; }
        if (skip === void 0) { skip = 0; }
        return {
            limit: limit,
            skip: skip,
            data: [],
            total: 0,
        };
    };
    FeathersRestService.prototype.handleError = function (e) {
        if (e.status === 401) {
            localStorage.removeItem('token');
            location.reload();
        }
        return throwError(e);
    };
    Object.defineProperty(FeathersRestService.prototype, "headers", {
        get: function () {
            var headers = new HttpHeaders({
                Accept: 'application/json',
                'Content-Type': 'application/json',
            });
            if (this.token) {
                headers = headers.append('Authorization', "Bearer " + this.token);
            }
            return headers;
        },
        enumerable: true,
        configurable: true
    });
    FeathersRestService.prototype.parseQuery = function (query) {
        var delimiter = '&';
        var parsedQuery = Object.keys(query).reduce(function (prev, key) {
            var current = query[key];
            if (Array.isArray(current)) {
                return current.reduce(function (currentVal, val, index) {
                    return currentVal + "&" + key + "[" + index + "]=" + encodeURIComponent(val);
                }, prev);
            }
            if (typeof current === 'object') {
                var j = 0;
                var _loop_1 = function (k, v) {
                    if (Array.isArray(v)) {
                        v.forEach(function (entry) {
                            prev += "&" + key + "[" + k + "]=" + encodeURIComponent(entry.toString());
                        });
                    }
                    else {
                        prev += "&" + key + "[" + k + "]=" + encodeURIComponent(v);
                    }
                    j++;
                };
                for (var _i = 0, _a = Object.entries(current); _i < _a.length; _i++) {
                    var _b = _a[_i], k = _b[0], v = _b[1];
                    _loop_1(k, v);
                }
                return prev;
            }
            if (prev) {
                return prev + "&" + key + "=" + encodeURIComponent('' + current);
            }
            return key + "=" + encodeURIComponent('' + current);
        }, '');
        if (parsedQuery.substr(0, 1) === '&') {
            return "?" + parsedQuery.substr(1);
        }
        return "?" + parsedQuery;
    };
    return FeathersRestService;
}());
export { FeathersRestService };
