import { Pipe, PipeTransform } from '@angular/core';

function formatMoney(price, places = 2, symbol = '€', thousand = '.', decimal = ',') {
  const negative = price < 0 ? '-' : '';

  let numberAsString = Math.abs(price).toFixed(places);
  const rest = `${decimal}${numberAsString.substr(-places)}${symbol}`;
  numberAsString = numberAsString.split('.').shift();

  const charsTotal = numberAsString.length;
  let formattedNumber = '';
  let tuples = Math.ceil(charsTotal / 3);
  let substrLength = charsTotal % 3 > 0 ? charsTotal % 3 : 3;
  while (tuples > 1) {
    formattedNumber += `${numberAsString.substr(0, substrLength)}${thousand}`;
    numberAsString = numberAsString.substr(substrLength);
    tuples--;
    substrLength = 3;
  }
  return `${negative}${formattedNumber}${numberAsString}${rest}`;
}

@Pipe({
  name: 'price'
})
export class PricePipe implements PipeTransform {
  transform(priceInCents: number): string {
    priceInCents = +priceInCents;

    if (isNaN(priceInCents) || !isFinite(priceInCents)) {
      return 'Preis nicht verfügbar.';
    }

    try {
      return formatMoney(priceInCents / 100);
    } catch (e) {
      return 'Preis nicht verfügbar.';
    }
  }
}
