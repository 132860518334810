import { Injectable } from '@angular/core';
import { AbstractDataSource } from './abstract-data-source';
import { Location, LocationsService } from './locations.service';
import { MatSnackBar } from '@angular/material';
import { ApiQuery } from './feathers-rest.service';

@Injectable()
export class LocationsDataSourceService extends AbstractDataSource<Location> {

  protected apiQuery: ApiQuery = {
    $client: {
      include: 1,
    }
  };

  constructor(protected locationsService: LocationsService,
              protected snackBar: MatSnackBar
  ) {
    super(locationsService, snackBar);
  }
}

