<div fxLayout="row"
     fxLayoutAlign="center center"
     style="min-height: 100%; height: 100%; margin-top: 2rem"
>
  <mat-card fxFlex="500px"
            fxFlexOffset="1rem"
            fxFlex.xs="90%"
            fxFlexOffset.xs=".5rem"
  >
    <mat-card-title>
      Willkommen
    </mat-card-title>
    <mat-card-content>
      <form [formGroup]="form" (ngSubmit)="submit()" fxLayout="column" fxLayoutAlign="center top">
        <div fxFlex fxFlexOffset=".5rem">
          <mat-form-field class="full-width">
            <input matInput
                   formControlName="username"
                   placeholder="Dein Benutzername"
            >
          </mat-form-field>
        </div>
        <div fxFlex fxFlexOffset=".5rem">
          <mat-form-field class="full-width">
            <input matInput
                   formControlName="password"
                   placeholder="Dein Passwort"
                   type="password"
            >
          </mat-form-field>
        </div>
        <div fxFlex fxFlexOffset=".5rem">
          <button mat-raised-button
                  color="primary"
                  type="submit"
                  class="full-width"
          >login
          </button>
        </div>
      </form>
    </mat-card-content>
  </mat-card>
</div>
