import * as tslib_1 from "tslib";
import { AbstractDataSource } from './abstract-data-source';
import { BookingsService } from './bookings.service';
import { MatSnackBar } from '@angular/material';
var BookingsDataSourceService = /** @class */ (function (_super) {
    tslib_1.__extends(BookingsDataSourceService, _super);
    function BookingsDataSourceService(bookingsService, snackBar) {
        var _this = _super.call(this, bookingsService, snackBar) || this;
        _this.bookingsService = bookingsService;
        _this.snackBar = snackBar;
        return _this;
    }
    Object.defineProperty(BookingsDataSourceService.prototype, "filter", {
        get: function () {
            return this._filter;
        },
        set: function (data) {
            this._filter = data;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(BookingsDataSourceService.prototype, "dateFilterProperty", {
        get: function () {
            return this._dateFilterProperty;
        },
        set: function (value) {
            this._dateFilterProperty = value;
        },
        enumerable: true,
        configurable: true
    });
    BookingsDataSourceService.prototype.reload = function () {
        var _this = this;
        var _a;
        if (this.inhibitApiLoad) {
            return this._data;
        }
        if (this.apiSubscription) {
            this.apiSubscription.unsubscribe();
        }
        this.selection.clear();
        var $limit = 10;
        var $skip = 0;
        if (this.paginator) {
            $limit = this.paginator.pageSize > 0 ? this.paginator.pageSize : 10;
            $skip = (+this.paginator.pageIndex) * $limit;
        }
        var $sort = {};
        if (this._sort && this._sort.active) {
            $sort = {};
            $sort[this.sort.active] = this.sort.direction === 'asc' ? 1 : -1;
        }
        var query = tslib_1.__assign({ $limit: $limit,
            $skip: $skip,
            $sort: $sort }, this._filter);
        if (this.dateFilterProperty) {
            query[this.dateFilterProperty] = (_a = {},
                _a[this._upcomingOnly ? '$gte' : '$lt'] = (new Date()).toISOString(),
                _a);
            query.$client = { include: 1 };
        }
        if (this._searchValue) {
            query.$search = this._searchValue;
        }
        this.apiSubscription = this.apiService
            .find(query)
            .subscribe(function (res) {
            _this._data.next(res.data);
            setTimeout(function () {
                if (_this.paginator) {
                    _this.paginator.length = res.total;
                }
            }, 0);
        }, function (e) {
            console.error(e);
            _this.snackBar.open('Daten konnten nicht geladen werden', 'Fehler');
        });
        return this._data;
    };
    return BookingsDataSourceService;
}(AbstractDataSource));
export { BookingsDataSourceService };
