import { Injectable } from '@angular/core';
import { CanActivate, CanActivateChild, CanLoad } from '@angular/router';
import { AccountService } from './account-service';

@Injectable()
export class AuthGuard implements CanActivate, CanActivateChild, CanLoad {

  constructor(private account: AccountService) {
  }

  canActivate(): boolean {
    return this.account.isAuthenticated;
  }

  canActivateChild() {
    return this.account.isAuthenticated;
  }

  canLoad() {
    return this.account.isAuthenticated;
  }
}
