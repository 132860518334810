import { Component, EventEmitter, Output } from '@angular/core';
import { AccountService } from '../shared/services/account-service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent {

  @Output()
  public toggleMenu = new EventEmitter();

  @Output()
  public logout = new EventEmitter();

  constructor(public account: AccountService) {
  }

}
