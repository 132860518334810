import { BehaviorSubject } from 'rxjs';
import { SelectionModel } from '@angular/cdk/collections';
import cloneDeep from 'lodash-es/cloneDeep';
var AbstractDataSource = /** @class */ (function () {
    function AbstractDataSource(apiService, snackBar) {
        this.apiService = apiService;
        this.snackBar = snackBar;
        this.apiQuery = {};
        this._data = new BehaviorSubject([]);
        this._searchValue = '';
        this.selection = new SelectionModel(true, []);
        this.inhibitApiLoad = true;
        this._upcomingOnly = true;
    }
    AbstractDataSource.prototype.connect = function () {
        this.reload();
        return this._data;
    };
    AbstractDataSource.prototype.disconnect = function () {
        this.selection.clear();
        this.inhibitApiLoad = true;
        if (this.apiSubscription) {
            this.apiSubscription.unsubscribe();
            this.apiSubscription = null;
        }
        if (this.paginatorSubscription) {
            this.paginatorSubscription.unsubscribe();
            this.paginatorSubscription = null;
            this._paginator = null;
        }
        if (this.sortSubscription) {
            this.sortSubscription.unsubscribe();
            this.sortSubscription = null;
            this._sort = null;
        }
    };
    Object.defineProperty(AbstractDataSource.prototype, "data", {
        get: function () {
            return this._data.value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AbstractDataSource.prototype, "paginator", {
        get: function () {
            return this._paginator;
        },
        set: function (paginator) {
            var _this = this;
            this._paginator = paginator;
            if (this.paginatorSubscription) {
                this.paginatorSubscription.unsubscribe();
            }
            this.paginatorSubscription = this.paginator.page.subscribe(function (ev) {
                localStorage.setItem('pageSize', '' + ev.pageSize);
                _this.reload();
            });
            this.reload();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AbstractDataSource.prototype, "upcomingOnly", {
        get: function () {
            return this._upcomingOnly;
        },
        set: function (enabled) {
            this._upcomingOnly = enabled;
            if (this.paginator) {
                this.paginator.pageIndex = 0;
            }
            this.reload();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AbstractDataSource.prototype, "sort", {
        get: function () {
            return this._sort;
        },
        set: function (sort) {
            var _this = this;
            if (this.sortSubscription) {
                this.sortSubscription.unsubscribe();
            }
            this._sort = sort;
            this.reload();
            if (!sort) {
                return;
            }
            this.sortSubscription = this.sort.sortChange.subscribe(function () {
                var args = [];
                for (var _i = 0; _i < arguments.length; _i++) {
                    args[_i] = arguments[_i];
                }
                _this.paginator.pageIndex = 0;
                _this.reload();
            });
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AbstractDataSource.prototype, "searchValue", {
        get: function () {
            return this._searchValue;
        },
        set: function (needle) {
            this._searchValue = needle;
            this.reload();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AbstractDataSource.prototype, "selectionLength", {
        get: function () {
            return this.selection.selected.length;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AbstractDataSource.prototype, "selected", {
        get: function () {
            return this.selection.selected;
        },
        enumerable: true,
        configurable: true
    });
    // selection adapter methods
    AbstractDataSource.prototype.isEveryRowSelected = function () {
        var numSelected = this.selection.selected.length;
        var numRows = this._data.value.length;
        return numSelected === numRows;
    };
    AbstractDataSource.prototype.isSelected = function (item) {
        return this.selection.isSelected(item);
    };
    AbstractDataSource.prototype.hasSelection = function () {
        return this.selection.hasValue();
    };
    AbstractDataSource.prototype.toggleSelection = function () {
        var _this = this;
        if (this.isEveryRowSelected()) {
            this.selection.clear();
            return;
        }
        this._data.value.forEach(function (row) { return _this.selection.select(row); });
    };
    AbstractDataSource.prototype.toggleSelect = function (item) {
        this.selection.toggle(item);
    };
    AbstractDataSource.prototype.ready = function () {
        this.inhibitApiLoad = false;
        return this;
    };
    AbstractDataSource.prototype.reload = function () {
        var _this = this;
        var _a;
        if (this.inhibitApiLoad) {
            return this._data;
        }
        if (this.apiSubscription) {
            this.apiSubscription.unsubscribe();
        }
        this.selection.clear();
        var $sort = {};
        if (this._sort && this._sort.active) {
            $sort = {};
            $sort[this.sort.active] = this.sort.direction === 'asc' ? 1 : -1;
        }
        var query = Object.assign(cloneDeep(this.apiQuery), {
            $sort: $sort
        });
        if (this.paginator) {
            query.$limit = this.paginator.pageSize > 0 ? this.paginator.pageSize : 10;
            query.$skip = (+this.paginator.pageIndex) * query.$limit;
        }
        if (this.dateFilterProperty) {
            query[this.dateFilterProperty] = (_a = {},
                _a[this._upcomingOnly ? '$gte' : '$lt'] = (new Date()).toISOString(),
                _a);
        }
        if (this._searchValue) {
            query.$search = this._searchValue;
        }
        this.apiSubscription = this.apiService
            .find(query)
            .subscribe(function (res) {
            _this._data.next(res.data);
            if (_this.paginator) {
                _this.paginator.length = res.total;
            }
        }, function (e) {
            console.error(e);
            _this.snackBar.open('Daten konnten nicht geladen werden', 'Fehler');
        });
        return this._data;
    };
    AbstractDataSource.prototype.pageSize = function () {
        var pageSize = 10;
        if (localStorage.getItem('pageSize')) {
            pageSize = +localStorage.getItem('pageSize');
        }
        return pageSize;
    };
    return AbstractDataSource;
}());
export { AbstractDataSource };
