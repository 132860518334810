import { Observable, throwError } from 'rxjs';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { catchError, first, map, shareReplay } from 'rxjs/operators';
import { AccountService } from './account-service';

export interface ApiFindResponse<T> {
  total: number;
  limit: number;
  skip: number;
  data: T[];
}

export interface ApiSortOperation {
  [key: string]: number | string;
}

export interface ApiOperatorQuery {
  $limit?: number;
  $skip?: number;
  $select?: string[];
  $search?: string;
  $sort?: ApiSortOperation;
  $client?: {
    [key: string]: number | string
  };

  [key: string]: string | number | string[] | ApiSortOperation;
}

export interface ApiQueryOperations {
  [key: string]: ApiSearchInQuery | ApiCompareQuery | ApiEqualQuery | ApiOrQuery;
}

export interface ApiSearchInQuery {
  $in?: string[] | number[];
  $nin?: string[] | number[];
}

export interface ApiCompareQuery {
  $lt?: number | string;
  $lte?: number | string;
  $gt?: number | string;
  $gte?: number | string;
  $ne?: number | string;
}

export interface ApiEqualQuery {
  [key: string]: number | string | boolean;
}

export interface ApiOrQuery {
  $or: {
    [key: string]: {
      ApiEqualQuery,
      ApiCompareQuery,
      ApiSearchInQuery
    }
  }[];
}

export type ApiQuery = ApiOperatorQuery | ApiQueryOperations;

export abstract class FeathersRestService<T> {

  protected token: string;

  protected apiUrl: String;

  constructor(
    protected account: AccountService,
    protected http: HttpClient,
  ) {
    this.account.token$.subscribe((token: string) => this.token = token);
  }

  public find(query: ApiQuery = {}): Observable<ApiFindResponse<T>> {
    return this.http
      .get<ApiFindResponse<T>>(`${this.apiUrl}/${this.parseQuery(query)}`, {
        headers: this.headers,
      })
      .pipe(
        first(),
        shareReplay(),
        catchError(e => this.handleError(e))
      );
  }

  public count(): Observable<number> {
    return this.http
      .get<ApiFindResponse<T>>(`${this.apiUrl}/?$limit=0`, {
        headers: this.headers,
      })
      .pipe(
        first(),
        map((res) => {
          return res.total;
        }),
        shareReplay(),
        catchError(e => this.handleError(e))
      );
  }

  public get(id, query: ApiQuery = {}): Observable<T> {
    return this.http
      .get<T>(`${this.apiUrl}/${id}${this.parseQuery(query)}`, {
        headers: this.headers,
      })
      .pipe(
        first(),
        shareReplay(),
        catchError(e => this.handleError(e))
      );
  }

  public create(data: T): Observable<T> {
    return this.http
      .post<T>(`${this.apiUrl}`, data, {
        headers: this.headers,
      })
      .pipe(
        first(),
        shareReplay(),
        catchError(e => this.handleError(e))
      );
  }

  public update(
    idOrCollection: number | string | Partial<T>,
    data: Partial<T> = null,
    query: ApiQuery = {}
  ): Observable<T | T[]> {
    const targetsSingleEntity = !Array.isArray(idOrCollection);
    let url = targetsSingleEntity ? `${this.apiUrl}/${idOrCollection}` : `${this.apiUrl}`;
    url += `/${this.parseQuery(query)}`;

    return this.http
      .patch<T | T[]>(url, targetsSingleEntity ? data : idOrCollection, {
        headers: this.headers,
      })
      .pipe(
        first(),
        shareReplay(),
        catchError(e => this.handleError(e))
      );
  }

  public replace(id, data: T): Observable<T> {
    return this.http
      .put<T>(`${this.apiUrl}/${id}`, data, {
        headers: this.headers,
      })
      .pipe(
        first(),
        shareReplay(),
        catchError(e => this.handleError(e))
      );
  }

  public delete(id): Observable<T> {
    return this.http
      .delete<T>(`${this.apiUrl}/${id}`, {
        headers: this.headers,
      })
      .pipe(
        first(),
        shareReplay(),
        catchError(e => this.handleError(e))
      );
  }

  public emptyFindResponse(limit = 0, skip = 0): ApiFindResponse<T> {
    return {
      limit,
      skip,
      data: [],
      total: 0,
    };
  }

  private handleError(e: HttpErrorResponse) {
    if (e.status === 401) {
      localStorage.removeItem('token');
      location.reload();
    }
    return throwError(e);
  }

  protected get headers() {
    let headers = new HttpHeaders({
      Accept: 'application/json',
      'Content-Type': 'application/json',
    });
    if (this.token) {
      headers = headers.append('Authorization', `Bearer ${this.token}`);
    }
    return headers;
  }

  protected parseQuery(query: ApiQuery) {
    const delimiter = '&';
    const parsedQuery = Object.keys(query).reduce((prev, key) => {
      const current = query[key];
      if (Array.isArray(current)) {
        return current.reduce((currentVal, val, index) => {
          return `${currentVal}&${key}[${index}]=${encodeURIComponent(val)}`;
        }, prev);
      }

      if (typeof current === 'object') {
        let j = 0;
        for (const [k, v] of Object.entries(current)) {
          if (Array.isArray(v)) {
            v.forEach((entry: string | number | boolean | null) => {
              prev += `&${ key }[${ k }]=${ encodeURIComponent(entry.toString()) }`;
            });
          } else {
            prev += `&${ key }[${ k }]=${ encodeURIComponent(<string>v) }`;
          }
          j++;
        }
        return prev;
      }
      if (prev) {
        return `${ prev }&${ key }=${encodeURIComponent('' + current)}`;
      }
      return `${ key }=${encodeURIComponent('' + current)}`;
    }, '');
    if (parsedQuery.substr(0, 1) === '&') {
      return `?${parsedQuery.substr(1)}`;
    }
    return `?${parsedQuery}`;
  }
}
