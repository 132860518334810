import { Injectable } from '@angular/core';
import { AbstractDataSource } from './abstract-data-source';
import { Booking, BookingsService } from './bookings.service';
import { MatSnackBar } from '@angular/material';
import { BehaviorSubject } from 'rxjs';
import { ApiFindResponse, ApiQuery, ApiSortOperation } from './feathers-rest.service';

export interface BookingsApiFilter {
  [key: string]: number | string | Date;
}

@Injectable()
export class BookingsDataSourceService extends AbstractDataSource<Booking> {

  protected _dateFilterProperty;

  protected _filter;

  constructor(protected bookingsService: BookingsService,
              protected snackBar: MatSnackBar
  ) {
    super(bookingsService, snackBar);
  }

  public set filter(data: BookingsApiFilter) {
    this._filter = data;
  }

  public get filter() {
    return this._filter;
  }

  public set dateFilterProperty(value: string) {
    this._dateFilterProperty = value;
  }

  public get dateFilterProperty() {
    return this._dateFilterProperty;
  }

  public reload(): BehaviorSubject<Booking[]> {
    if (this.inhibitApiLoad) {
      return this._data;
    }
    if (this.apiSubscription) {
      this.apiSubscription.unsubscribe();
    }
    this.selection.clear();

    let $limit = 10;
    let $skip = 0;
    if (this.paginator) {
      $limit = this.paginator.pageSize > 0 ? this.paginator.pageSize : 10;
      $skip = (+this.paginator.pageIndex) * $limit;
    }

    let $sort: ApiSortOperation = {};
    if (this._sort && this._sort.active) {
      $sort = {};
      $sort[this.sort.active] = this.sort.direction === 'asc' ? 1 : -1;
    }

    const query: ApiQuery = {
      $limit,
      $skip,
      $sort,
      ...this._filter
    };

    if (this.dateFilterProperty) {
      query[this.dateFilterProperty] = {
        [this._upcomingOnly ? '$gte' : '$lt']: (new Date()).toISOString()
      };
      query.$client = {include: 1};
    }

    if (this._searchValue) {
      query.$search = this._searchValue;
    }

    this.apiSubscription = this.apiService
      .find(query)
      .subscribe(
        (res: ApiFindResponse<Booking>) => {
          this._data.next(res.data);
          setTimeout(() => {
            if (this.paginator) {
              this.paginator.length = res.total;
            }
          }, 0);
        },
        (e) => {
          console.error(e);
          this.snackBar.open('Daten konnten nicht geladen werden', 'Fehler');
        }
      );
    return this._data;
  }
}
