import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { NotFoundComponent } from './not-found/not-found.component';
import { AuthGuard } from './shared/services/auth-guard.service';
import { ChangePasswordComponent } from './shared/components/change-password/change-password.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: '/dashboard',
    pathMatch: 'full'
  },
  {
    path: 'dashboard',
    loadChildren: './dashboard/dashboard.module#DashboardModule',
    canLoad: [AuthGuard]
  },
  {
    path: 'bookings',
    loadChildren: './bookings/bookings.module#BookingsModule',
    canLoad: [AuthGuard]
  },
  {
    path: 'locations',
    loadChildren: './locations/locations.module#LocationsModule',
    canLoad: [AuthGuard]
  },
  {
    path: 'payments',
    loadChildren: './payments/payments.module#PaymentsModule',
    canLoad: [AuthGuard]
  },
  {
    path: 'events',
    loadChildren: './events/events.module#EventsModule',
    canLoad: [AuthGuard]
  },
  {
    path: 'users',
    loadChildren: './users/users.module#UsersModule',
    canLoad: [AuthGuard]
  },
  {
    path: 'change-password',
    component: ChangePasswordComponent,
    canActivate: [AuthGuard]
  },
  {
    path: '**',
    component: NotFoundComponent,
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      preloadingStrategy: PreloadAllModules,
      useHash: true
    })
  ],
  exports: [
    RouterModule
  ]
})
export class AppRoutingModule {
}
