import { Injectable } from '@angular/core';
import { FeathersRestService } from './feathers-rest.service';
import { AccountService } from './account-service';
import { HttpClient } from '@angular/common/http';

export interface Location {
  id?: number;
  locationPageId?: number;
  contactPerson: string;
  contactPhone: string;
  contactMail: string;
  name: string;
  city: string;
  street: string;
  postal: string;
  active?: boolean;
  logoId?: string;
  defaultSeats?: number;
  defaultTicketPriceCent: number;
  voucherPriceCent: number;
  createdAt?: string;
  updatedAt?: string;
  'payment.id'?: number;
}

@Injectable()
export class LocationsService extends FeathersRestService<Location> {

  apiUrl = '/api/location';

  constructor(
    protected account: AccountService,
    protected http: HttpClient,
  ) {
    super(account, http);
  }

}
