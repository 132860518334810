import * as tslib_1 from "tslib";
import { PaymentsService } from './payments.service';
import { AbstractDataSource } from './abstract-data-source';
import { MatSnackBar } from '@angular/material';
var PaymentsDataSourceService = /** @class */ (function (_super) {
    tslib_1.__extends(PaymentsDataSourceService, _super);
    function PaymentsDataSourceService(paymentsService, snackBar) {
        var _this = _super.call(this, paymentsService, snackBar) || this;
        _this.paymentsService = paymentsService;
        _this.snackBar = snackBar;
        _this.apiQuery = {
            $client: {
                include: 1,
            }
        };
        return _this;
    }
    return PaymentsDataSourceService;
}(AbstractDataSource));
export { PaymentsDataSourceService };
