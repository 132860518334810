import { Component, OnDestroy } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { AccountService } from '../../services/account-service';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material';
import { Router } from '@angular/router';
import { User } from '../../services/users.service';
import { takeWhile } from 'rxjs/operators';

@Component({
  selector: 'app-login',
  styleUrls: ['./login.component.scss'],
  templateUrl: './login.component.html',
})
export class LoginComponent implements OnDestroy {

  private isAlive = true;

  public form;

  constructor(
    fb: FormBuilder,
    private account: AccountService,
    private snackBar: MatSnackBar,
    private router: Router
  ) {
    this.form = fb.group({
      username: new FormControl('', [Validators.required]),
      password: new FormControl('', [Validators.required]),
    });
  }

  ngOnDestroy() {
    this.isAlive = false;
  }

  submit() {
    if (this.form.valid) {
      this.account
        .auth(this.form.value)
        .pipe(
          takeWhile(() => this.isAlive)
        )
        .subscribe((user: User) => {
          this.snackBar.open(
            'Login erfolgreich',
            `Willkommen ${user.username}`,
            <MatSnackBarConfig>{
              duration: 5000
            });
          if (user.forcePasswordChange) {
            this.router.navigate(['change-password']);
          } else {
            this.router.navigate(['dashboard']);
          }
        }, (e) => {
          this.snackBar.open('Login fehlgeschlagen.', '', <MatSnackBarConfig>{
            duration: 5000
          });
        });
    }
  }

}
