import { Injectable } from '@angular/core';
import { AbstractDataSource } from './abstract-data-source';
import { MatSnackBar } from '@angular/material';
import { User, UsersService } from './users.service';

@Injectable()
export class UsersDataSourceService extends AbstractDataSource<User> {

  constructor(protected usersService: UsersService, protected snackBar: MatSnackBar) {
    super(usersService, snackBar);
  }
}
