<section [ngClass]="'render-' + activeMq">
  <app-header (toggleMenu)="toggleSidebar()" (logout)="logout()"></app-header>
  <mat-sidenav-container>
    <mat-sidenav #sidebar
                 *ngIf="account?.isAuthenticated"
                 [mode]="mode"
                 [opened]="isSidebarOpen"
    >
      <mat-nav-list>
        <mat-divider></mat-divider>
        <a routerLink="dashboard" routerLinkActive="active" mat-list-item (click)="closeWhenMobile()">
          <mat-icon>dashboard</mat-icon>
          Dashboard
        </a>
        <mat-divider></mat-divider>
        <a routerLink="bookings" routerLinkActive="active" mat-list-item (click)="closeWhenMobile()">
          <mat-icon>group</mat-icon>
          Buchungen
        </a>
        <mat-divider></mat-divider>
        <a routerLink="events" routerLinkActive="active" mat-list-item (click)="closeWhenMobile()">
          <mat-icon>event</mat-icon>
          Veranstaltungen
        </a>
        <mat-divider></mat-divider>
        <a routerLink="locations" routerLinkActive="active" mat-list-item (click)="closeWhenMobile()">
          <mat-icon>room_service</mat-icon>
          Veranstaltungsorte
        </a>
        <mat-divider></mat-divider>
        <a routerLink="payments" routerLinkActive="active" mat-list-item (click)="closeWhenMobile()">
          <mat-icon>payments</mat-icon>
          Kontoverbindungen
        </a>
        <mat-divider></mat-divider>
        <a *ngIf="account.isAdmin"
           routerLink="users"
           routerLinkActive="active"
           mat-list-item
           (click)="closeWhenMobile()"
        >
          <mat-icon>supervised_user_circle</mat-icon>
          Benutzer verwalten
        </a>
        <mat-divider></mat-divider>
      </mat-nav-list>
    </mat-sidenav>
    <mat-sidenav-content>
      <main>
        <app-login *ngIf="!account?.isAuthenticated"></app-login>
        <router-outlet *ngIf="account?.isAuthenticated"></router-outlet>
      </main>
    </mat-sidenav-content>
  </mat-sidenav-container>
</section>



