import { Injectable } from '@angular/core';
import { FeathersRestService } from './feathers-rest.service';
import { AccountService } from './account-service';
import { HttpClient } from '@angular/common/http';

export interface Payment {
  id?: number;
  locationId: number;
  accountName: string;
  bankName: string;
  iban: string;
  bic: string;
  'location.name'?: string;
}


@Injectable()
export class PaymentsService extends FeathersRestService<Payment> {

  apiUrl = '/api/payment';

  constructor(
    protected account: AccountService,
    protected http: HttpClient,
  ) {
    super(account, http);
  }

}
