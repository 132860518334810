import { DataSource } from '@angular/cdk/table';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { MatPaginator, MatSnackBar, MatSort, PageEvent } from '@angular/material';
import { SelectionModel } from '@angular/cdk/collections';
import cloneDeep from 'lodash-es/cloneDeep';

import { ApiFindResponse, ApiQuery, ApiSortOperation, FeathersRestService } from './feathers-rest.service';

export abstract class AbstractDataSource<T> implements DataSource<T> {

  protected dateFilterProperty;

  protected apiQuery: ApiQuery = {};

  protected _data: BehaviorSubject<T[]> = new BehaviorSubject([]);

  protected _paginator: MatPaginator | null;

  protected _sort: MatSort | null;

  protected _searchValue = '';

  protected sortSubscription: Subscription;

  protected apiSubscription: Subscription;

  protected paginatorSubscription: Subscription;

  protected selection = new SelectionModel<T>(true, []);

  protected inhibitApiLoad = true;

  protected _upcomingOnly = true;

  constructor(protected apiService: FeathersRestService<T>,
              protected snackBar: MatSnackBar
  ) {
  }

  public connect(): Observable<T[]> {
    this.reload();
    return this._data;
  }

  public disconnect() {
    this.selection.clear();
    this.inhibitApiLoad = true;
    if (this.apiSubscription) {
      this.apiSubscription.unsubscribe();
      this.apiSubscription = null;
    }
    if (this.paginatorSubscription) {
      this.paginatorSubscription.unsubscribe();
      this.paginatorSubscription = null;
      this._paginator = null;
    }
    if (this.sortSubscription) {
      this.sortSubscription.unsubscribe();
      this.sortSubscription = null;
      this._sort = null;
    }
  }

  public get data() {
    return this._data.value;
  }

  public set paginator(paginator: MatPaginator | null) {
    this._paginator = paginator;
    if (this.paginatorSubscription) {
      this.paginatorSubscription.unsubscribe();
    }
    this.paginatorSubscription = this.paginator.page.subscribe((ev: PageEvent) => {
      localStorage.setItem('pageSize', '' + ev.pageSize);
      this.reload();
    });
    this.reload();
  }

  public get upcomingOnly() {
    return this._upcomingOnly;
  }

  public set upcomingOnly(enabled: boolean) {
    this._upcomingOnly = enabled;
    if (this.paginator) {
      this.paginator.pageIndex = 0;
    }
    this.reload();
  }

  public get paginator(): MatPaginator | null {
    return this._paginator;
  }

  public set sort(sort: MatSort | null) {
    if (this.sortSubscription) {
      this.sortSubscription.unsubscribe();
    }

    this._sort = sort;
    this.reload();

    if (!sort) {
      return;
    }
    this.sortSubscription = this.sort.sortChange.subscribe((...args) => {
      this.paginator.pageIndex = 0;
      this.reload();
    });
  }

  public get sort(): MatSort | null {
    return this._sort;
  }

  public set searchValue(needle: string) {
    this._searchValue = needle;
    this.reload();
  }

  public get searchValue() {
    return this._searchValue;
  }

  public get selectionLength() {
    return this.selection.selected.length;
  }

  public get selected() {
    return this.selection.selected;
  }

  // selection adapter methods
  public isEveryRowSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this._data.value.length;

    return numSelected === numRows;
  }

  public isSelected(item: T) {
    return this.selection.isSelected(item);
  }

  public hasSelection() {
    return this.selection.hasValue();
  }

  public toggleSelection() {
    if (this.isEveryRowSelected()) {
      this.selection.clear();
      return;
    }
    this._data.value.forEach(row => this.selection.select(row));
  }

  public toggleSelect(item: T) {
    this.selection.toggle(item);
  }

  public ready() {
    this.inhibitApiLoad = false;
    return this;
  }

  public reload(): BehaviorSubject<T[]> {
    if (this.inhibitApiLoad) {
      return this._data;
    }
    if (this.apiSubscription) {
      this.apiSubscription.unsubscribe();
    }
    this.selection.clear();

    let $sort: ApiSortOperation = {};
    if (this._sort && this._sort.active) {
      $sort = {};
      $sort[this.sort.active] = this.sort.direction === 'asc' ? 1 : -1;
    }

    const query: ApiQuery = Object.assign(cloneDeep(this.apiQuery), {
      $sort
    });

    if (this.paginator) {
      query.$limit = this.paginator.pageSize > 0 ? this.paginator.pageSize : 10;
      query.$skip = (+this.paginator.pageIndex) * query.$limit;
    }

    if (this.dateFilterProperty) {
      query[this.dateFilterProperty] = {
        [this._upcomingOnly ? '$gte' : '$lt']: (new Date()).toISOString()
      };
    }

    if (this._searchValue) {
      query.$search = this._searchValue;
    }

    this.apiSubscription = this.apiService
      .find(query)
      .subscribe(
        (res: ApiFindResponse<T>) => {
          this._data.next(res.data);
          if (this.paginator) {
            this.paginator.length = res.total;
          }
        },
        (e) => {
          console.error(e);
          this.snackBar.open('Daten konnten nicht geladen werden', 'Fehler');
        }
      );
    return this._data;
  }

  public pageSize() {
    let pageSize = 10;
    if (localStorage.getItem('pageSize')) {
      pageSize = +localStorage.getItem('pageSize');
    }
    return pageSize;
  }
}

