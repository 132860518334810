import * as tslib_1 from "tslib";
import { EventsService } from './events.service';
import { AbstractDataSource } from './abstract-data-source';
import { MatSnackBar } from '@angular/material';
var EventsDataSourceService = /** @class */ (function (_super) {
    tslib_1.__extends(EventsDataSourceService, _super);
    function EventsDataSourceService(eventsService, snackBar) {
        var _this = _super.call(this, eventsService, snackBar) || this;
        _this.eventsService = eventsService;
        _this.snackBar = snackBar;
        _this.dateFilterProperty = 'beginsAt';
        _this.apiQuery = {
            $client: {
                include: 1,
                sumTicketsSold: 1,
            }
        };
        return _this;
    }
    return EventsDataSourceService;
}(AbstractDataSource));
export { EventsDataSourceService };
