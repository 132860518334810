import { Injectable } from '@angular/core';
import { AccountService } from './account-service';
import { FeathersRestService } from './feathers-rest.service';
import { HttpClient } from '@angular/common/http';

export interface User {
  id?: number;
  username: string;
  email: string;
  forcePasswordChange: boolean;
  password?: string;
  role: 'admin' | 'location';
  locationId: number;
  createdAt?: number;
  updatedAt?: number;
}

@Injectable()
export class UsersService extends FeathersRestService<User> {

  protected apiUrl = '/api/user';

  constructor(
    protected account: AccountService,
    protected http: HttpClient,
  ) {
    super(account, http);
  }

}
