import * as tslib_1 from "tslib";
import { FeathersRestService } from './feathers-rest.service';
import { AccountService } from './account-service';
import { HttpClient } from '@angular/common/http';
var PaymentsService = /** @class */ (function (_super) {
    tslib_1.__extends(PaymentsService, _super);
    function PaymentsService(account, http) {
        var _this = _super.call(this, account, http) || this;
        _this.account = account;
        _this.http = http;
        _this.apiUrl = '/api/payment';
        return _this;
    }
    return PaymentsService;
}(FeathersRestService));
export { PaymentsService };
