<div fxLayout="row"
     fxLayoutAlign="center center"
     style="min-height: 100%; height: 100%; margin-top: 2rem"
>
  <mat-card fxFlex="500px"
            fxFlexOffset="1rem"
            fxFlex.xs="280px"
            fxFlexOffset.xs=".5rem"
  >
    <mat-card-header>
      <mat-card-title>Passwort ändern</mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <form [formGroup]="form" (ngSubmit)="submit()" fxLayout="column" fxLayoutAlign="center top">
        <div fxFlex fxFlexOffset=".5rem">
          <mat-form-field class="full-width">
            <input matInput
                   formControlName="newPw"
                   placeholder="Neues Passwort"
                   type="password"
            >
          </mat-form-field>
        </div>
        <div fxFlex fxFlexOffset=".5rem">
          <mat-form-field class="full-width">
            <input matInput
                   formControlName="pwRepeat"
                   placeholder="Passwort wiederholen"
                   type="password"
            >
          </mat-form-field>
        </div>
        <div fxFlex fxFlexOffset=".5rem">
          <button mat-raised-button
                  color="accent"
                  type="submit"
                  class="full-width"
          >ändern
          </button>
        </div>
      </form>
    </mat-card-content>
  </mat-card>
</div>
