import { Injectable } from '@angular/core';
import { Payment, PaymentsService } from './payments.service';
import { AbstractDataSource } from './abstract-data-source';
import { MatSnackBar } from '@angular/material';
import { ApiQuery } from './feathers-rest.service';

@Injectable()
export class PaymentsDataSourceService extends AbstractDataSource<Payment> {

  protected apiQuery: ApiQuery = {
    $client: {
      include: 1,
    }
  };

  constructor(protected paymentsService: PaymentsService, protected snackBar: MatSnackBar) {
    super(paymentsService, snackBar);
  }
}
